@import '../styles/Variables/Var.scss';

.member-wrap{
    margin: 50px;
    text-align: center;

    @media screen and (max-width: 1000px) {
        margin: 50px 10px;
    }

    .member-title{
        padding: 40px 0;
        width: 80%;
        margin: auto;

        @media screen and (max-width: 1000px) {
            width: 100%;
            padding: 0px;
        }

         h1{
             font-family: $BebasNeue;
             font-size: 45px;
             color: $mainColor;

             @media screen and (max-width: 800px) {
                 letter-spacing: 1px;
             }
         }

         p{
             font-family: $Jost;
             font-size: 20px;
             color: $dark;

             @media screen and (max-width: 800px) {
                 font-size: 20px;
             }
         }
    }

    .member-offers{
        padding: 20px 0;

        .offers-grid{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 30px;

            @media screen and (max-width: 1370px) {
                grid-gap: 7px;
            }

            @media screen and (max-width: 1200px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media screen and (max-width: 800px) {
                grid-template-columns: repeat(1, 1fr);
            }

            .offers-column{
                box-shadow: 0px 2px 6px 1px rgba(156,156,156,0.75);
                -webkit-box-shadow: 0px 2px 6px 1px rgba(156,156,156,0.75);
                -moz-box-shadow: 0px 2px 6px 1px rgba(156,156,156,0.75);
                background-color: #ffffff;

                .member-img{
                    position: relative;
                    img{
                        width: 100%;
                    }

                    .member-titleB{
                        background-color: $mainColor;
                        padding: 10px 0 0 0;
                        position: absolute;
                        bottom: 20px;
                        width: 80%;
                        margin-left: 40px;
                        transform: skewX(-30deg);

                        // @media screen and (max-width: 1366px) {
                        //     margin-left: 30px;
                        // }

                        p{
                            color: $white;
                            transform: skewX(30deg);
                            font-family: $Jost;
                        }
                    }
                }

                .member-desc{
                    font-family: $Jost;
                    font-size: 15px;
                    padding: 20px 10px;
                    color: $dark;
                }
            }
        }
    }

    //inquire btn
    .inquire-btn{
        margin: 30px 0 50px 0;
    }

    a button{
        @include mem-Btn();

        :hover{
            @include btnHover();
        }
    }
}

    //members' benefits
    .benefits-wrap{
        margin: 100px;

             @media screen and (max-width: 1000px) {
                 margin: 100px 10px;
             }

             .benefits-grid{
                 display: grid;
                 grid-gap: 20px;
                 grid-template-columns: repeat(2, 1fr);

                 @media screen and (max-width: 1000px) {
                    grid-template-columns: repeat(1, 1fr);
                 }

                 .benefits-col{
                    .vid{
                        background-color: red;
                    }

                     h1{
                         font-family: $BebasNeue;
                         font-size: 45px;
                         color: $mainColor;
                     }

                     .benefits-lists{
                         ul > li {
                             list-style-type: none;
                             display: flex;
                             text-align: left;
                         }

                         .learn-btn{
                             margin-top: 30px;
                             margin-left: 30px;

                             @media screen and (max-width: 800px) {
                                margin-left: 0;
                                margin-bottom: 50px;
                             }

                             button{
                                 @include regBtn();
                                 padding: 15px 20px;
                                 background-color: $mainColor;
                                 font-family: $Jost;
                                 color: $white;
                                 cursor: pointer;
                                 transition: 350ms ease-in-out;
                             }

                             :hover{
                                 @include btnHover();
                             }
                         }
                     }

                     .check-icon{
                         margin: auto;
                         font-size: 20px;
                         color: $mainColor;
                         margin: auto 30px;

                         @media screen and (max-width: 800px) {
                             display: none;
                         }
                     }

                     .benefits-desc{
                         margin: 15px 0;

                         p{
                             font-size: 20px;
                             font-family: $Jost;
                             text-align: left;
                             color: $dark;

                             @media screen and (max-width: 800px) {
                                 font-size: 18px;
                             }
                         }
                     }

                     .benefits-vid{
                         width: auto;
                         text-align: center;
                         margin: 0 50px;

                         @media screen and (max-width: 1366px) {
                            margin: 0px;
                            margin-top: 20px;
                        }

                         @media screen and (max-width: 800px) {
                            margin: 0 0 50px 0;
                         }

                         img{
                            width: 100%;
                         }

                         @media screen and (max-width: 1366px) {
                            height: 250px;
                        }
                     }
                 }
             }
        }