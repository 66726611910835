@import '../styles/Variables/Var.scss';

.modal-wrap{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #000000ad;
    z-index: 99999;
    display: none;

    .modal-box{
        background-color: #ffffff;
        padding: 30px 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 3px;
        text-align: center;
        font-family: $Jost;

        @media screen and (max-width: 800px) {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 25px 10px;
            width: 80%;
        }

        .checkIcon{
            font-size: 100px;
            color: $darkGreen;

            @media screen and (max-width: 800px) {
                font-size: 80px;
            }
        }

        h1{
            font-size: 35px;
            color: #242424;

            @media screen and (max-width: 800px) {
                font-size: 30px;
            }
        }

        p{
            font-size: 18px;
            color: #3F3F3F;

            @media screen and (max-width: 800px) {
                font-size: 15px;
            }
        }

        .exit-btn{
            width: 100%;
            text-align: center;

            button{
                width: 90%;
                margin-top: 15px;
                @include regBtn();
                background-color: $darkGreen;
                color: $white;
                padding: 10px 0;
                font-size: 14px;
                font-family: $Jost;
                cursor: pointer;

                @media screen and (max-width: 800px) {
                    padding: 8px 0;
                    font-size: 12px;
                }
            }
        }
    }
}

.modal-wrap.active{
    display: block;
}