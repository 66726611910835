@import '../styles/Variables/Var.scss';

.igHeader{
    text-align: center;
    padding: 30px 0;

    .title{
        display: flex;
        justify-content: center;

        margin-bottom: 20px;

        .ig{
            color: $mainColor;
            font-size: 35px;
        }

        p{
            font-size: 30px;
            font-weight: bold;
            padding-left: 5px;
            color: $darkGreen;
            font-family: $Jost;
        }
    }

    .ig-desc{
        font-family: $Jost;
        font-size: 20px;
        width: 50%;
        margin: auto;
        margin-bottom: 20px;

        @media screen and (max-width: 1000px) {
            width: 95%;
            font-size: 18px;
        }
    }

    .follow-btn{
        button{
            padding: 10px 20px;
            @include regBtn();
            cursor: pointer;
            background-color: transparent;
            border: 2px solid $mainColor;
            border-radius: 5px;
            color: $mainColor;
            font-weight: 600;

            @media screen and (max-width: 1000px) {
                padding: 7px 20px;
            }

            .plus-icon{
                font-weight: bold;
            }
        }

        :hover{
            color: white;
            background-color: $mainColor;
            transition: 350ms ease-in-out;
        }
    }
}