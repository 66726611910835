@import '../styles/Variables/Var.scss';

.extend-carousel{
    height: 100%;

    .carousel{
        height: 100%;
        width: 100%;

        .cell{
            width: 100%;
            height: 90vh;

            @media screen and (max-width: 1000px){
                height: 350px;
            }

            img{
                height: 100vh;
                width: 100%;
                object-fit: cover;

                @media screen and (max-width: 1000px){
                    height: 350px;
                }
            }
        }
    }

    //benefits
    .benefits{
        background-color: #fdfdfd;
        position: relative;
        width: 400px;
        bottom: 350px;
        margin-left: 50px;
        padding: 20px;
        color: $mainColor;
        font-family: $Jost;
        border-radius: 3px;

        @media screen and (max-width: 1000px){
            bottom: 0px;
            width: auto;
            margin: 50px 0;
            background-color: #fdfdfd75;
        }

        h1{
            font-size: 40px;

            @media screen and (max-width: 1000px){
                font-size: 30px;
            }
        }

        p{
            font-size: 18px;
            font-weight: 500;
        }

        a{
            text-decoration: none;
        }

        .mem-benefit{
            padding: 10px 0px;
            @include regBtn();
            background-color: transparent;
            color: $mainColor;
            font-family: $Jost;
            margin-top: 10px;
            font-weight: 500;
            cursor: pointer;
            display: flex;
            align-items: center;

            p{
                font-size: 14px;
                transition: 2s ease-in-out;

                @media screen and (max-width: 1000px){
                    font-size: 14px;
                }
            }

            :hover{
                text-decoration: underline;
            }
        }
    }
}


