@import '../styles/Variables/Var.scss';

.nav-wrap{
    background-color: #00000000;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
    transition: .5s ease-in-out;
    padding: 5px 0 0 0;

    @media screen and (max-width: 768px) {
        padding: 0;
    }
}
    .active{
        background-color: #0000006e;
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.377);
    }


.nav-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 100px;

    @media screen and (max-width: 1000px) {
        padding: 6px 14px;
    }

    .nav-logo{
        .logo{
            width: 200px;
            height: 50px;
            margin-top: 0px;

            @media screen and (max-width: 1000px) {
                width: 140px;
                height: 35px;
            }
        }
    }

    .nav-logo button{
        cursor: pointer;
    }
}

.nav-logo button{
    cursor: pointer;
}

.nav-bar-btn{
    button{
        @include heroBtn($white);
        font-size: 45px;

        @media screen and (max-width: 1000px) {
            bottom: 15px;
            font-size: 35px;
        }

        @media screen and (max-width: 768px) {
            font-size: 35px;
        }

        :hover{
            color: $lightGreen;
            transition: 0.3s ease-in-out;
        }
    }

    .menuIcon{
        @media screen and (min-width: 1366px) {
            margin-bottom: 15px;
        }

        @media screen and (max-width: 768px) {
            margin-bottom: 12px;
        }
    }

    :before{
        content: 'MENU';
        display: inline-block;
        font-size: 16px;
        font-family: $Jost;
        position: relative;
        bottom: 5px;
        margin-right: 9px;

        @media screen and (min-width: 1366px) {
            bottom: 13px;
        }

        @media screen and (max-width: 1000px) {
            font-size: 12px;
            bottom: 5px;
        }

        @media screen and (max-width: 768px) {
            bottom: 10px;
        }
    }
}

//backdrop
.backdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #000000ce;
    z-index: 9;
    display: none;
}

.backdrop.active{
    display: block;
}

//SIDEBAR
.nav-menu{
    width: 400px;
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    z-index: 99999;
    transition: 1500ms;
    padding: 140px 30px 0 0px;

    @media screen and (max-width: 1366px){
        padding: 90px 30px 0 0px;
    }

    @media screen and (max-width: 800px) {
        width: 100%;
        padding: 50px 30px 0 0px;
    }
}

.nav-menu.active{
    background-color: #ffffff;
    right: 0;
    transition: 400ms;
    box-shadow: 0 0 15px 0px rgb(0, 0, 0);
}


.nav-toggle{
    list-style-type: none;
    padding: 15px 25px;

    h1{
        position: absolute;
        color: #125b3b1f;
        left: 0;
        top: 10px;
        font-size: 62px;
        line-height: 70px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: bold;

        @media screen and (max-width: 800px) {
            font-size: 45px;
            display: none;
        }

        @media screen and (max-width: 1366px) {
            font-size: 60px;
            line-height: 50px;
        }
    }
}

.nav-toggle button{
    @include heroBtn($white);
    font-size: 35px;
    color: $mainColor;
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 35px;

    :hover{
        color: $lightGreen;
        transition: 0.3s ease-in-out;
    }
}

.nav-links{
    list-style: none;
    text-align: left;
    margin: 5px 0;
    font-size: 0.70em;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(0.25turn, #82a782, #a4e7ab, rgba(194, 194, 194, 0.596));
    border-image-slice: 1;
    width: 100%;

    @media screen and (max-width: 1366px) {
        padding: 0px 0px 10px 0px;
    }

    .text-link{
        color: #125b3bec;
        text-decoration: none;
        padding: 34px 0 18px 0px;
        width: 100%;
        display: table;
        font-weight: 600;

        @media screen and (max-width: 1366px) {
            padding: 28px 0 2px 0px;
        }
    }

    .text-link.active-text{
        font-weight: 500;
        color: #125B3B;
    }

    .text-link:hover{
        color: $lightGreen;
        text-decoration: none;
        transition: 0.3s ease-in-out;
    }

}

.nav-links.active-border{
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(0.25turn, rgb(41, 95, 41), #a4e7ab, rgba(194, 194, 194, 0.596));
    border-image-slice: 1;
    width:100%;
}

.nav-items{
    text-decoration: none;
}

.nav-body{
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100vh;
}

.nav-out{
    background-color: rgba(3, 3, 3, 0);
    z-index: -1;
    width: 100%;
    height: 100vh;
}

//COPYRIGHT
.copyright{
    position: absolute;
    color: #080808cb;
    bottom: 0;
    left: 0;
    margin-bottom: 30px;
    font-family: $Jost;
    font-size: 14px;
    width: 100%;
    text-align: center;

    @media screen and (max-width: 800px) {
        font-size: 12px;
    }
}