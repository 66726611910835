@media screen and (max-width: 1000px) {
  .classes-wrap {
    margin: 50px 10px;
  }
}
.classes-wrap .class-title {
  text-align: center;
  margin: 50px 0;
}
.classes-wrap .class-title h1 {
  font-family: "Bebas Neue", cursive;
  font-size: 45px;
  color: #125B3B;
}
@media screen and (max-width: 800px) {
  .classes-wrap .class-title h1 {
    letter-spacing: 1px;
  }
}
.classes-wrap .class-title p {
  font-family: "Jost", sans-serif;
  font-size: 20px;
  color: #212121;
}
.classes-wrap .offers-list {
  background-color: rgba(0, 163, 91, 0.031372549);
}
.classes-wrap .offers-list .offers-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media screen and (max-width: 1000px) {
  .classes-wrap .offers-list .offers-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .classes-wrap .offers-list .offers-grid .elementB {
    grid-column-start: 1;
    grid-row-start: 1;
  }
}
.classes-wrap .offers-list .offers-grid .offers-col .offers-img img {
  width: 100%;
}
.classes-wrap .offers-list .offers-grid .offers-col .offers-description {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-30%, -50%);
  margin: auto;
  font-family: "Jost", sans-serif;
}
@media screen and (max-width: 1000px) {
  .classes-wrap .offers-list .offers-grid .offers-col .offers-description {
    position: static;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
    padding: 30px 0;
  }
}
.classes-wrap .offers-list .offers-grid .offers-col .offers-description h1 {
  font-size: 40px;
  color: #125B3B;
}
.classes-wrap .offers-list .offers-grid .offers-col .offers-description h4 {
  color: #125B3B;
}
.classes-wrap .offers-list .offers-grid .offers-col .offers-description p {
  font-size: 18px;
  margin: 15px 0 30px 0;
}
.classes-wrap .offers-list .offers-grid .offers-col .package-btn {
  padding: 15px 30px;
  font-size: 14px;
  font-family: "Jost", sans-serif;
  cursor: pointer;
  background-color: #125B3B;
  outline: none;
  border: none;
  border-radius: 3px;
  color: #FFFFFF;
  font-weight: 600;
  transition: 350ms ease-in-out;
}
.classes-wrap .offers-tag {
  text-align: center;
  margin-top: 80px;
  font-family: "Jost", sans-serif;
  color: #125B3B;
}
.classes-wrap .offers-tag h1 {
  font-weight: 500;
  font-size: 40px;
}
@media screen and (max-width: 800px) {
  .classes-wrap .offers-tag h1 {
    font-size: 24px;
  }
}
.classes-wrap .offers-btn {
  text-align: center;
  margin: 30px 0 80px 0;
}
.classes-wrap .offers-btn button {
  padding: 15px 30px;
  font-size: 14px;
  font-family: "Jost", sans-serif;
  cursor: pointer;
  background-color: #125B3B;
  outline: none;
  border: none;
  border-radius: 3px;
  color: #FFFFFF;
  font-weight: 600;
  transition: 350ms ease-in-out;
}
@media screen and (max-width: 800px) {
  .classes-wrap .offers-btn button {
    padding: 10px 20px;
    font-size: 14px;
  }
}
.classes-wrap .offers-btn :hover {
  transform: scale(1.1);
  box-shadow: 0 5px 10px rgba(81, 87, 83, 0.2274509804);
  background-color: #083823;
}

.package-wrap {
  background-color: rgba(0, 0, 0, 0.8901960784);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  opacity: 0;
  transition: 350ms ease-in-out;
  pointer-events: none;
  will-change: transform, opacity;
  transform: scale(1.1);
  transition: transform 0.5s cubic-bezier(0.465, 0.183, 0.153, 0.946), opacity 0.5s cubic-bezier(0.465, 0.183, 0.153, 0.946);
  overflow: hidden;
}
.package-wrap .close-modal {
  text-align: right;
}
.package-wrap .close-modal button {
  background-color: transparent;
  border: none;
  outline: none;
}
.package-wrap .close-modal .closeIcon {
  color: #ffffff;
  font-size: 35px;
  margin: 10px 10px 0 0;
  cursor: pointer;
}
.package-wrap .package-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.package-wrap .package-box .package-details img {
  height: 95vh;
  width: auto;
}
@media screen and (max-width: 800px) {
  .package-wrap .package-box .package-details img {
    height: 60vh;
    width: 95vw;
  }
}

.package-wrap.active {
  overflow: hidden;
  opacity: 1;
  pointer-events: auto;
  transform: scale(1);
  transition: transform 0.5s cubic-bezier(0.465, 0.183, 0.153, 0.946), opacity 0.5s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}/*# sourceMappingURL=classes.css.map */