@import '../styles/Variables/Var.scss';

.footer{
    font-family: $Jost;

    .news-wrap{
        background-color: $darkGreen;
        padding: 50px 0;
    }

    .footer-wrap{
        background-color: $mainColor;
        padding: 50px 0px;
        color: $white;

        @media (max-width: 1000px) {
            padding: 30px 0px;
        }

        .footer-grid{
            width: 80%;
            margin: auto;
            display: grid;
            grid-template-columns: 1.4fr 3fr;
            grid-gap: 20px;

            @media screen and (max-width: 1366px) {
                width: 90%;
            }

            @media screen and (max-width: 800px) {
                width: 90%;
                grid-template-columns: 1fr;
            }

            .main-col{

                .news-col-sec{
                    @media screen and (max-width: 1000px) {
                        text-align: center;
                    }

                    p{
                        text-align: left;
                        font-size: 18px;
                        font-family: $Jost;
                        font-weight: 500;

                        @media screen and (max-width: 1400px) {
                            font-size: 16px;
                        }

                        @media screen and (max-width: 1000px) {
                            text-align: center;
                        }

                    }

                    .news-input{
                        padding: 10px 0;
                        display: flex;
                        margin-left: 25px;

                        @media (max-width: 1000px) {
                            justify-content: center;
                            margin-left: 0;
                        }

                        input[type=email]{
                            padding: 6px 20px 6px 6px;
                            width: 50%;
                            border: none;
                            outline: none;
                            border-radius: 3px 0 0 3px;
                            font-family: $Jost;
                            font-size: 14px;

                            @media (max-width: 800px) {
                                padding: 8px 15px;
                                width: 70%;
                            }
                        }

                        .subscribe{
                            padding: 6px 10px;
                            border: none;
                            outline: none;
                            background-color: $darkGreen;
                            color: $white;
                            border-radius: 0 3px 3px 0;
                            font-family: $Jost;
                            font-size: 14px;
                            cursor: pointer;

                            @media (max-width: 800px) {
                                padding: 8px 15px;
                            }
                        }

                        .hid{
                            display: none;
                        }
                    }
                }

                .col-grid{
                    display: grid;
                    grid-template-columns: 3fr 2fr 2fr;
                    grid-gap: 30px;
                    margin-top: 20px;

                    @media (max-width: 1000px) {
                        grid-template-columns: 1fr;
                    }

                    .col-grid-right{
                        h1{
                            font-size: 18px;
                            margin-bottom: 10px;
                        }

                        p{
                            font-size: 14px;
                            padding: 5px 0;
                        }

                        .ftrLink{
                            text-decoration: none;
                            color: $white;
                        }

                        @media (max-width: 800px) {
                            text-align: center;
                        }
                    }
                }

            }

            .left{

                .ftr-logo-m{
                    @media (max-width: 800px) {
                        text-align: center;
                    }
                }

                img{
                    width: 370px;
                    margin: auto;

                    @media (max-width: 1440px) {
                        width: 310px;
                    }
                }

                p{
                    text-align: left;
                    padding-left: 25px;

                    @media (max-width: 1000px) {
                        text-align: center;
                        padding-left: 0;
                    }
                }
            }
        }

        .breaker{
            border-bottom: 1px solid #b9c0bc4f;
            width: 90%;
            margin: 25px auto;

            @media screen and (max-width: 800px){
                width: 100%;
                margin: 30px auto;            }
        }

        .footer-bottom-grid{

            .socs-col-img{
                padding: 10px 0;

                img{
                    margin: 0 8px;
                    cursor: pointer;
                }
            }

            width: 80%;
            margin: auto;
            text-align: center;
            font-size: 14px;

            @media (max-width: 800px) {
                width: 90%;
                margin-bottom: 50px;
            }
        }
    }

}