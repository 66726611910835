.footer {
  font-family: "Jost", sans-serif;
}
.footer .news-wrap {
  background-color: #083823;
  padding: 50px 0;
}
.footer .footer-wrap {
  background-color: #125B3B;
  padding: 50px 0px;
  color: #FFFFFF;
}
@media (max-width: 1000px) {
  .footer .footer-wrap {
    padding: 30px 0px;
  }
}
.footer .footer-wrap .footer-grid {
  width: 80%;
  margin: auto;
  display: grid;
  grid-template-columns: 1.4fr 3fr;
  grid-gap: 20px;
}
@media screen and (max-width: 1366px) {
  .footer .footer-wrap .footer-grid {
    width: 90%;
  }
}
@media screen and (max-width: 800px) {
  .footer .footer-wrap .footer-grid {
    width: 90%;
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 1000px) {
  .footer .footer-wrap .footer-grid .main-col .news-col-sec {
    text-align: center;
  }
}
.footer .footer-wrap .footer-grid .main-col .news-col-sec p {
  text-align: left;
  font-size: 18px;
  font-family: "Jost", sans-serif;
  font-weight: 500;
}
@media screen and (max-width: 1400px) {
  .footer .footer-wrap .footer-grid .main-col .news-col-sec p {
    font-size: 16px;
  }
}
@media screen and (max-width: 1000px) {
  .footer .footer-wrap .footer-grid .main-col .news-col-sec p {
    text-align: center;
  }
}
.footer .footer-wrap .footer-grid .main-col .news-col-sec .news-input {
  padding: 10px 0;
  display: flex;
  margin-left: 25px;
}
@media (max-width: 1000px) {
  .footer .footer-wrap .footer-grid .main-col .news-col-sec .news-input {
    justify-content: center;
    margin-left: 0;
  }
}
.footer .footer-wrap .footer-grid .main-col .news-col-sec .news-input input[type=email] {
  padding: 6px 20px 6px 6px;
  width: 50%;
  border: none;
  outline: none;
  border-radius: 3px 0 0 3px;
  font-family: "Jost", sans-serif;
  font-size: 14px;
}
@media (max-width: 800px) {
  .footer .footer-wrap .footer-grid .main-col .news-col-sec .news-input input[type=email] {
    padding: 8px 15px;
    width: 70%;
  }
}
.footer .footer-wrap .footer-grid .main-col .news-col-sec .news-input .subscribe {
  padding: 6px 10px;
  border: none;
  outline: none;
  background-color: #083823;
  color: #FFFFFF;
  border-radius: 0 3px 3px 0;
  font-family: "Jost", sans-serif;
  font-size: 14px;
  cursor: pointer;
}
@media (max-width: 800px) {
  .footer .footer-wrap .footer-grid .main-col .news-col-sec .news-input .subscribe {
    padding: 8px 15px;
  }
}
.footer .footer-wrap .footer-grid .main-col .news-col-sec .news-input .hid {
  display: none;
}
.footer .footer-wrap .footer-grid .main-col .col-grid {
  display: grid;
  grid-template-columns: 3fr 2fr 2fr;
  grid-gap: 30px;
  margin-top: 20px;
}
@media (max-width: 1000px) {
  .footer .footer-wrap .footer-grid .main-col .col-grid {
    grid-template-columns: 1fr;
  }
}
.footer .footer-wrap .footer-grid .main-col .col-grid .col-grid-right h1 {
  font-size: 18px;
  margin-bottom: 10px;
}
.footer .footer-wrap .footer-grid .main-col .col-grid .col-grid-right p {
  font-size: 14px;
  padding: 5px 0;
}
.footer .footer-wrap .footer-grid .main-col .col-grid .col-grid-right .ftrLink {
  text-decoration: none;
  color: #FFFFFF;
}
@media (max-width: 800px) {
  .footer .footer-wrap .footer-grid .main-col .col-grid .col-grid-right {
    text-align: center;
  }
}
@media (max-width: 800px) {
  .footer .footer-wrap .footer-grid .left .ftr-logo-m {
    text-align: center;
  }
}
.footer .footer-wrap .footer-grid .left img {
  width: 370px;
  margin: auto;
}
@media (max-width: 1440px) {
  .footer .footer-wrap .footer-grid .left img {
    width: 310px;
  }
}
.footer .footer-wrap .footer-grid .left p {
  text-align: left;
  padding-left: 25px;
}
@media (max-width: 1000px) {
  .footer .footer-wrap .footer-grid .left p {
    text-align: center;
    padding-left: 0;
  }
}
.footer .footer-wrap .breaker {
  border-bottom: 1px solid rgba(185, 192, 188, 0.3098039216);
  width: 90%;
  margin: 25px auto;
}
@media screen and (max-width: 800px) {
  .footer .footer-wrap .breaker {
    width: 100%;
    margin: 30px auto;
  }
}
.footer .footer-wrap .footer-bottom-grid {
  width: 80%;
  margin: auto;
  text-align: center;
  font-size: 14px;
}
.footer .footer-wrap .footer-bottom-grid .socs-col-img {
  padding: 10px 0;
}
.footer .footer-wrap .footer-bottom-grid .socs-col-img img {
  margin: 0 8px;
  cursor: pointer;
}
@media (max-width: 800px) {
  .footer .footer-wrap .footer-bottom-grid {
    width: 90%;
    margin-bottom: 50px;
  }
}/*# sourceMappingURL=Footer.css.map */