.member-wrap {
  margin: 50px;
  text-align: center;
}
@media screen and (max-width: 1000px) {
  .member-wrap {
    margin: 50px 10px;
  }
}
.member-wrap .member-title {
  width: 80%;
  margin: auto;
}
@media screen and (max-width: 1000px) {
  .member-wrap .member-title {
    width: 100%;
    padding: 0px;
  }
}
.member-wrap .member-title h1 {
  font-family: "Bebas Neue", cursive;
  font-size: 45px;
  color: #125B3B;
}
@media screen and (max-width: 800px) {
  .member-wrap .member-title h1 {
    letter-spacing: 1px;
  }
}
.member-wrap .member-title p {
  font-family: "Jost", sans-serif;
  font-size: 20px;
  color: #212121;
}
@media screen and (max-width: 800px) {
  .member-wrap .member-title p {
    font-size: 20px;
  }
}
.member-wrap .member-offers {
  padding: 20px 0;
}
.member-wrap .member-offers .offers-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
@media screen and (max-width: 1370px) {
  .member-wrap .member-offers .offers-grid {
    grid-gap: 7px;
  }
}
@media screen and (max-width: 1200px) {
  .member-wrap .member-offers .offers-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 800px) {
  .member-wrap .member-offers .offers-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.member-wrap .member-offers .offers-grid .offers-column {
  box-shadow: 0px 2px 6px 1px rgba(156, 156, 156, 0.75);
  -webkit-box-shadow: 0px 2px 6px 1px rgba(156, 156, 156, 0.75);
  -moz-box-shadow: 0px 2px 6px 1px rgba(156, 156, 156, 0.75);
  background-color: #ffffff;
}
.member-wrap .member-offers .offers-grid .offers-column .member-img {
  position: relative;
}
.member-wrap .member-offers .offers-grid .offers-column .member-img img {
  width: 100%;
}
.member-wrap .member-offers .offers-grid .offers-column .member-img .member-titleB {
  background-color: #125B3B;
  padding: 10px 0 0 0;
  position: absolute;
  bottom: 20px;
  width: 80%;
  margin-left: 40px;
  transform: skewX(-30deg);
}
.member-wrap .member-offers .offers-grid .offers-column .member-img .member-titleB p {
  color: #FFFFFF;
  transform: skewX(30deg);
  font-family: "Jost", sans-serif;
}
.member-wrap .member-offers .offers-grid .offers-column .member-desc {
  font-family: "Jost", sans-serif;
  font-size: 15px;
  padding: 20px 10px;
  color: #212121;
}
.member-wrap .inquire-btn {
  margin: 30px 0 50px 0;
}
.member-wrap a button {
  padding: 15px 30px;
  font-size: 14px;
  font-family: "Jost", sans-serif;
  cursor: pointer;
  background-color: #125B3B;
  outline: none;
  border: none;
  border-radius: 3px;
  color: #FFFFFF;
  font-weight: 600;
  transition: 350ms ease-in-out;
}
.member-wrap a button :hover {
  transform: scale(1.1);
  box-shadow: 0 5px 10px rgba(81, 87, 83, 0.2274509804);
  background-color: #083823;
}

.benefits-wrap {
  margin: 100px;
}
@media screen and (max-width: 1000px) {
  .benefits-wrap {
    margin: 100px 10px;
  }
}
.benefits-wrap .benefits-grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
}
@media screen and (max-width: 1000px) {
  .benefits-wrap .benefits-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.benefits-wrap .benefits-grid .benefits-col .vid {
  background-color: red;
}
.benefits-wrap .benefits-grid .benefits-col h1 {
  font-family: "Bebas Neue", cursive;
  font-size: 45px;
  color: #125B3B;
}
.benefits-wrap .benefits-grid .benefits-col .benefits-lists ul > li {
  list-style-type: none;
  display: flex;
  text-align: left;
}
.benefits-wrap .benefits-grid .benefits-col .benefits-lists .learn-btn {
  margin-top: 30px;
  margin-left: 30px;
}
@media screen and (max-width: 800px) {
  .benefits-wrap .benefits-grid .benefits-col .benefits-lists .learn-btn {
    margin-left: 0;
    margin-bottom: 50px;
  }
}
.benefits-wrap .benefits-grid .benefits-col .benefits-lists .learn-btn button {
  outline: none;
  border: none;
  border-radius: 3px;
  padding: 15px 20px;
  background-color: #125B3B;
  font-family: "Jost", sans-serif;
  color: #FFFFFF;
  cursor: pointer;
  transition: 350ms ease-in-out;
}
.benefits-wrap .benefits-grid .benefits-col .benefits-lists .learn-btn :hover {
  transform: scale(1.1);
  box-shadow: 0 5px 10px rgba(81, 87, 83, 0.2274509804);
  background-color: #083823;
}
.benefits-wrap .benefits-grid .benefits-col .check-icon {
  margin: auto;
  font-size: 20px;
  color: #125B3B;
  margin: auto 30px;
}
@media screen and (max-width: 800px) {
  .benefits-wrap .benefits-grid .benefits-col .check-icon {
    display: none;
  }
}
.benefits-wrap .benefits-grid .benefits-col .benefits-desc {
  margin: 15px 0;
}
.benefits-wrap .benefits-grid .benefits-col .benefits-desc p {
  font-size: 20px;
  font-family: "Jost", sans-serif;
  text-align: left;
  color: #212121;
}
@media screen and (max-width: 800px) {
  .benefits-wrap .benefits-grid .benefits-col .benefits-desc p {
    font-size: 18px;
  }
}
.benefits-wrap .benefits-grid .benefits-col .benefits-vid {
  width: auto;
  text-align: center;
  margin: 0 50px;
}
@media screen and (max-width: 1366px) {
  .benefits-wrap .benefits-grid .benefits-col .benefits-vid {
    margin: 0px;
    margin-top: 20px;
  }
}
@media screen and (max-width: 800px) {
  .benefits-wrap .benefits-grid .benefits-col .benefits-vid {
    margin: 0 0 50px 0;
  }
}
.benefits-wrap .benefits-grid .benefits-col .benefits-vid img {
  width: 100%;
}
@media screen and (max-width: 1366px) {
  .benefits-wrap .benefits-grid .benefits-col .benefits-vid {
    height: 250px;
  }
}/*# sourceMappingURL=Membership.css.map */

