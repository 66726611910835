@import '../styles/Variables/Var.scss';
.pop-wrap{
    .pop.active{
        position: fixed;
        bottom: 0;
        right: 50px;
        text-align: right;
        transition: 850ms;
        background-color: transparent;
        box-shadow: none;

        @media screen and (max-width: 800px) {
            position: fixed;
            bottom: 0;
            right: 0;
            left: 0;
            text-align: center;
            transition: 850ms;
            background-color: none;
        }
    }

    .pop{
        bottom: -100%;
        transition: 850ms;
    }


    button{
        padding: 15px 70px;
        font-size: 16px;
        font-family: $Jost;
        border-radius: 0;
        border: none;
        outline: none;
        background-color: $darkGreen;
        color: $white;
        cursor: pointer;

        @media screen and (max-width: 800px) {
            padding: 12px 60px;
            font-size: 14px;
        }
    }
}