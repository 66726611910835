@import '../styles/Variables/Var.scss';

.faci-wrap{
    margin: 50px 100px;

    @media screen and (max-width: 1000px){
        margin: 30px 10px;
    }

    .title{
        text-align: center;
        padding: 20px 0;
        font-family: $Jost;
        font-size: 40px;
        color: $mainColor;
        font-weight: $heavy;

        @media screen and (max-width: 800px){
            font-size: 35px;
        }
            padding: 0 0 20px 0;
        @media screen and (max-width: 500px){
            font-size: 35px;
        }
    }

    .gallery-grid{
        text-align: center;
    }

    .gallery{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;

        @media screen and (max-width: 1000px){
            grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (max-width: 800px){
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .grid-col{
        position: relative;
        width: 100%;
    }

    .image-section{
        width: 100%;
    }

    .image-section img{
        width: 100%;
        height: auto;
        display: block;
    }

    .content{
        background-color: rgba(0, 0, 0, 0.493);
        font-family: $Jost;
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        color: rgb(255, 255, 255);
        transition: 0.5s ease;
        font-size: 26px;
        // overflow: hidden;
        opacity: 0;
        font-weight: $heavy;
    }

    .grid-col:hover .content{
        opacity: 1;
    }

    .faci-btn{
        text-align: center;
        margin: 70px 0 10px 0;

        @media screen and (max-width: 1000px){
            margin: 30px 0 50px 0;
        }

        button{
            @include mem-Btn();
            transition: 350ms ease-in-out;

            @media screen and (max-width: 1000px){
                padding: 10px 30px;
                font-size: 14px;
            }
        }

        :hover{
            @include btnHover();
        }
    }

    .facilities-end-desc{
        margin: 50px 0;
        width: 85%;
        margin: 50px auto;

        @media screen and (max-width: 1000px) {
            width: 100%;
        }

        h1{
            font-size: 24px;
            text-align: center;
            font-family: $Jost;
            color: $mainColor;
            font-weight: 500;

            @media screen and (max-width: 1000px) {
                font-size: 25px;
            }

            @media screen and (max-width: 800px) {
                font-size: 18px;
            }

        }

        .inquire-btn{
            text-align: center;
            margin-top: 30px;

            button{
                @include mem-Btn();

                @media screen and (max-width: 1000px) {
                    padding: 10px 30px;
                    font-size: 16px;
                }
            }

            :hover{
                @include btnHover();
            }
        }

    }
}

//facilities modal
.hero-img{

    .facility-modal{
        font-family: $Jost;
        color: $white;
        background-color: $white;
        position: absolute;
        text-align: left;
        top: 80%;
        left: 12%;
        transform: translate(-12%, -80%);
        padding: 20px 30px;
        width: 35%;
        border-radius: 2px;

        @media screen and (max-width: 1000px) {
            width: 95%;
            top: 75%;
            left: 50%;
            transform: translate(-50%, -75%);
            padding: 10px 10px;
            background-color: #1d1d1d75;
        }

        h1{
            font-size: 50px;
            font-weight: 600;
            margin-bottom: 10px;
            color: $mainColor;

            @media screen and (max-width: 1000px) {
                font-size: 34px;
                color: $white;
            }
        }

        p{
            font-size: 18px;
            margin-bottom: 30px;
            color: $mainColor;

            @media screen and (max-width: 1000px) {
                font-size: 14px;
                margin-bottom: 15px;
                color: $white;
            }
        }

        .faci-inquire-btn{

            button{
                @include mem-Btn();

                    @media screen and (max-width: 1000px) {
                        padding: 5px 20px;
                        font-size: 12px;
                    }
            }
            :hover{
                @include btnHover();
            }
        }
    }
}

//facility separate page
.hero-wrap{
    .hero-img img{
        height: 100vh;
        width: 100%;
        object-fit: cover;

        @media screen and (max-width: 1000px) {
            height: 70vh;
        }

        @media screen and (max-width: 800px) {
            height: 50vh;
        }
    }
}