@import '../styles/Variables/Var.scss';

.offers-wrap{

    @media screen and (max-width: 1000px) {
        margin: 50px 10px;
    }

    .offers-title{
        text-align: center;
        margin: 50px 0;

        h1{
            font-family: $BebasNeue;
            font-size: 45px;
            color: $mainColor;

            @media screen and (max-width: 800px) {
                letter-spacing: 1px;
            }
        }

        p{
            font-family: $Jost;
            font-size: 20px;
            color: $dark;
        }
    }

    .offers-list{
        background-color: #00a35b08;

        .offers-grid{
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            @media screen and (max-width: 1000px) {
                grid-template-columns: repeat(1, 1fr);

                .elementB{
                    grid-column-start: 1;
                    grid-row-start: 1;
                }

            }
            .offers-col{
                .offers-img{
                    img{
                        width: 100%;
                    }
                }

                .offers-description{
                    text-align: center;
                    position: absolute;
                    top: 50%;
                    left: 40%;
                    transform: translate(-30%, -50%);
                    margin: auto;
                    font-family: $Jost;

                    @media screen and (max-width: 1000px) {
                        position: static;
                        top: 0%;
                        left: 0%;
                        transform: translate(-0%, -0%);
                        padding: 30px 0;
                    }

                    h1{
                        font-size: 40px;
                        color: $mainColor;
                    }

                    p{
                        font-size: 18px;
                        margin: 15px 0 30px 0;
                    }
                }

                .package-btn{
                    @include mem-Btn();
                }

            }
        }
    }

    .offers-tag{
        text-align: center;
        margin-top: 80px;
        font-family: $Jost;
        color: $mainColor;

        h1{
            font-weight: 500;
            font-size: 40px;

            @media screen and (max-width: 800px) {
                font-size: 24px;
            }
        }
    }

    .offers-btn{
        text-align: center;
        margin: 30px 0 80px 0;

        button{
            @include mem-Btn();

            @media screen and (max-width: 800px) {
                padding: 10px 20px;
                font-size: 14px;
            }
        }

        :hover{
            @include btnHover();
        }
    }
}

.package-wrap{
    background-color: #000000e3;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    // display: none;
    opacity: 0;
    transition: 350ms ease-in-out;
    pointer-events: none;
    will-change: transform, opacity;
    transform: scale(1.1);
    transition:
    transform 0.5s cubic-bezier(0.465, 0.183, 0.153, 0.946),
    opacity 0.5s cubic-bezier(0.465, 0.183, 0.153, 0.946);
    overflow: hidden;

    .close-modal{
        text-align: right;

        button{
            background-color: transparent;
            border: none;
            outline: none;
        }

        .closeIcon{
            color: #ffffff;
            font-size: 35px;
            margin: 10px 10px 0 0;
            cursor: pointer;
        }
    }

    .package-box{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .package-details{
            img{
                height: 95vh;
                width: auto;

                @media screen and (max-width: 800px) {
                    height: 60vh;
                    width: 95vw;
                }
            }
        }
    }
}

.package-wrap.active{
    overflow: hidden;
    opacity: 1;
    pointer-events: auto;
    transform: scale(1);
    transition:
    transform 0.5s cubic-bezier(0.465, 0.183, 0.153, 0.946),
    opacity 0.5s cubic-bezier(0.465, 0.183, 0.153, 0.946);
}