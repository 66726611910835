@import '../styles/Variables/Var.scss';

.carousel-wrap{
    height: 100%;
    position: relative;

    .carousel{
        height: 100%;
        width: 100%;

        .cell{
            width: 100%;
            height: 100vh;

            @media screen and (max-width: 1000px){
                height: 350px;
            }

            img{
                height: 100vh;
                width: 100%;

                @media screen and (max-width: 1000px){
                    height: 350px;
                }
            }
        }
    }

    .benefits{
        position: absolute;
        top: 78%;
        left: 13%;
        transform: translate(-50%, -20%);
        margin: auto;
        margin-left: 50px;
        width: 400px;
        padding: 20px;
        color: $mainColor;
        font-family: $Jost;
        border-radius: 3px;
        background-color: $white;

        @media screen and (max-width: 1366px) {
            top: 70%;
            margin-left: 70px;
        }

        @media screen and (max-width: 1000px){
            position: static;
            top: 0%;
            left: 0%;
            transform: translate(0%, 0%);
            margin-left: 0px;
            width: 100%;
            text-align: left;
            margin-top: 20px;
        }

        h1{
            font-size: 30px;

            @media screen and (max-width: 1000px){
                font-size: 30px;
            }
        }

        p{
            font-size: 16px;
            font-weight: 500;
        }

        a{
            text-decoration: none;
        }

        .mem-benefit{
            padding: 10px 0px;
            @include regBtn();
            background-color: transparent;
            color: $mainColor;
            font-family: $Jost;
            margin-top: 10px;
            font-weight: 500;
            cursor: pointer;
            display: flex;
            align-items: center;


            .car-arrow{
                font-size: 20px;
                font-weight: 500;
            }

            p{
                font-size: 13px;
                transition: 2s ease-in-out;

                @media screen and (max-width: 1000px){
                    font-size: 14px;
                }
            }

            :hover{
                text-decoration: underline;
            }
        }
    }
}


