.act-wrap {
  background-color: #125B3B;
  font-family: "Jost", sans-serif;
  padding: 50px 0;
}
@media screen and (max-width: 800px) {
  .act-wrap {
    padding: 50px 0 0 0;
  }
}
.act-wrap .act-box {
  text-align: center;
  padding: 0;
}
.act-wrap .act-box p {
  font-size: 40px;
  color: #FFFFFF;
  font-weight: 700;
  margin-top: 15px;
}
@media screen and (max-width: 800px) {
  .act-wrap .act-box p {
    margin-top: 0px;
  }
}
@media screen and (max-width: 1000px) {
  .act-wrap .act-box p {
    font-size: 40px;
  }
}
@media screen and (max-width: 800px) {
  .act-wrap .act-box p {
    font-size: 30px;
  }
}
.act-wrap .act-slider {
  display: grid;
  height: 250px;
  margin: auto;
  position: relative;
  width: 80%;
  place-items: center;
  overflow: hidden;
}
@media screen and (max-width: 1000px) {
  .act-wrap .act-slider {
    width: 100%;
    height: 250px;
  }
}
@media screen and (max-width: 800px) {
  .act-wrap .act-slider {
    width: 100%;
    height: 180px;
  }
}
.act-wrap .act-slider .slide-track {
  display: flex;
  width: 3600px;
  animation: scroll 40s linear infinite;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-1800px);
  }
}
.act-wrap .act-slider .slide-track .slide {
  height: 150px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
}
@media screen and (max-width: 1000px) {
  .act-wrap .act-slider .slide-track .slide {
    height: 150px;
    width: 200px;
    margin: 0 5px;
  }
}
@media screen and (max-width: 800px) {
  .act-wrap .act-slider .slide-track .slide {
    height: 120px;
    width: 150px;
    margin: 0 1px;
  }
}
.act-wrap .act-slider .slide-track .slide img {
  width: 50%;
}
@media screen and (max-width: 1000px) {
  .act-wrap .act-slider .slide-track .slide img {
    width: 40%;
  }
}
.act-wrap .act-slider .slide-track .slide p {
  color: #FFFFFF;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
}
@media screen and (max-width: 800px) {
  .act-wrap .act-slider .slide-track .slide p {
    font-size: 14px;
  }
}/*# sourceMappingURL=Activities.css.map */